<template>
  <div>
    <atoms-dropdown :hide-drop-icons="iconOnly">
      <template #title>
        <NuxtImg
          v-if="userDetails.picture"
          class="rounded-full w-6 h-6"
          :alt="userDetails.given_name"
          :src="userDetails.picture"
          width="32"
          height="32"
        />
        <p
          v-if="!iconOnly"
          class="text-sm text-dark font-bold ml-2"
        >
          {{ userDetails.given_name }}
        </p>
      </template>

      <template #content>
        <ul>
          <li>
            <NuxtLink
              id="dashboard_nav_btn"
              class="flex items-center w-full text-dark text-sm py-3 px-5 font-normal hover:font-bold"
              :to="{name:'dashboard'}"
            >
              <NuxtImg
                width="16"
                height="16"
                class="mr-3"
                src="/icons/home-outline.svg"
                alt="Dashboard"
              />
              Dashboard
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              id="dashboard_nav_btn"
              class="flex items-center w-full text-dark text-sm py-3 px-5 font-normal hover:font-bold"
              to="/dashboard/account-settings/"
            >
              <NuxtImg
                width="16"
                height="16"
                class="mr-3"
                src="/icons/settings-outline.svg"
                alt="Account Settings"
              />
              Account Settings
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              id="auth_logout_btn"
              class="flex items-center w-full text-dark text-sm py-3 px-5 font-normal hover:font-bold"
              type="button"
              @click="handleLogout"
            >
              <NuxtImg
                width="16"
                height="16"
                class="mr-3"
                src="/icons/power-off-dark.svg"
                alt="Logout"
              />
              Logout
            </NuxtLink>
          </li>
        </ul>
      </template>
    </atoms-dropdown>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesNavProfile'
})
const userStore = useUserStore()
const appStore = useLayoutsStore()
const router = useRouter()
defineProps({
  iconOnly: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  nextTick().then(() => {
    /*
     | ======================================================
     | Listener for global logout events
     | Specially handles logout events from external packages
     | ======================================================
     */
    window?.document?.getElementById('app-main')?.addEventListener('logout', () => {
      handleLogout()
    })
  })
})

/*
 * Auth User details
 * @type {ComputedRef<*>}
 */
const userDetails = computed(() => userStore.getUserProfile)

/*
 * Logout handler
 */
function handleLogout () {
  appStore.setPageLoader(true)
  nextTick().then(() => {
    userStore.logout()
      .finally(() => {
        router.push('/login/')
        appStore.setPageLoader(false)
      })
  })
}
</script>

<style scoped>
.router-link-active {
  @apply bg-gray-200 font-bold;
}
a:hover {
  @apply bg-gray-300 font-bold;
}
</style>
