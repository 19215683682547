/**
 * This file contains the configuration required for rendering the GlobalNavBar Component
 */

const INSURANCE_MEGAMENU_OBJ = {
  'Health Insurance': {
    title: 'Health Insurance',
    firstColumn: {
      title: 'Who is this cover for?',
      links: [
        {
          title: 'Singles',
          url: '/health-insurance/singles/',
          image: '/images/menu/common/singles.svg'
        },
        {
          title: 'Couples',
          url: '/health-insurance/couples/',
          image: '/images/menu/common/couples.svg'
        },
        {
          title: 'Families',
          url: '/health-insurance/families/',
          image: '/images/menu/common/families.svg'
        },
        {
          title: 'Seniors',
          url: '/health-insurance/seniors/',
          image: '/images/menu/common/seniors.svg'
        },
        {
          title: 'Newly Married',
          url: '/health-insurance/newly-married-couples/',
          image: '/images/menu/common/newly-married.svg'
        },
        {
          title: 'Single Parents',
          url: '/health-insurance/single-parents/',
          image: '/images/menu/common/single-parents.svg'
        }
      ]
    },

    secondColumn: {
      title: 'Cover by type',
      links: [
        {
          title: 'Hospital Cover',
          url: '/health-insurance/hospital-cover/',
          image: '/images/menu/health-insurance/hospital-cover.svg'

        },
        {
          title: 'Extras Cover',
          url: '/health-insurance/extras-cover/',
          image: '/images/menu/health-insurance/extras-cover.svg'
        },
        {
          title: 'Ambulance Cover',
          url: '/health-insurance/ambulance-cover/',
          image: '/images/menu/health-insurance/ambulance-cover.svg'
        },
        {
          title: 'Dental Cover',
          url: '/health-insurance/dental/',
          image: '/images/menu/health-insurance/dental-cover.svg'
        },
        {
          title: 'Optical Cover',
          url: '/health-insurance/optical/',
          image: '/images/menu/health-insurance/optical-cover.svg'
        },
        {
          title: 'Pregnancy',
          url: '/health-insurance/pregnancy/',
          image: '/images/menu/health-insurance/pregnancy.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Health Insurance Explained',
      links: [
        {
          title: 'All guides',
          url: '/health-insurance/guides/',
          underline: true
        }
      ]
    },

    details: {
      productPage: '/health-insurance/'
    }
  },
  'Overseas Visitor Health Cover': {
    title: 'Overseas Visitor Health Cover',
    firstColumn: {
      title: 'Who is this cover for?',
      links: [
        {
          title: 'Singles',
          url: '/health-insurance/visitors-visa-ovhc/',
          image: '/images/menu/common/singles.svg'
        },
        {
          title: 'Couples',
          url: '/health-insurance/visitors-visa-ovhc/',
          image: '/images/menu/common/couples.svg'
        },
        {
          title: 'Parents',
          url: '/health-insurance/visitors-visa-ovhc/',
          image: '/images/menu/common/parents.svg'
        }
      ]
    },
    secondColumn: {
      title: 'Cover by type',
      links: [
        {
          title: 'Student Cover',
          url: '/health-insurance/student-visitors-australia/',
          image: '/images/menu/ovhc/student-cover.svg'
        },
        {
          title: 'Working in Australia',
          url: '/health-insurance/457-visas/',
          image: '/images/menu/ovhc/working-in-australia.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Overseas visitor health cover explained',
      links: []
    },

    details: {
      productPage: '/health-insurance/visitors-visa-ovhc/'
    }
  },
  'Life Insurance': {
    title: 'Life Insurance',

    firstColumn: {
      title: 'Who is this cover for?',
      links: [
        {
          title: 'Over 50',
          url: '/life-insurance/over-50s/',
          image: '/images/menu/common/over-50.svg'
        },
        {
          title: 'Over 65',
          url: '/life-insurance/over-65/',
          image: '/images/menu/common/over-65.svg'
        },
        {
          title: 'Singles',
          url: '/life-insurance/singles/',
          image: '/images/menu/common/singles.svg'
        },
        {
          title: 'Couples',
          url: '/life-insurance/couples/',
          image: '/images/menu/common/couples.svg'
        },
        {
          title: 'Single Parents',
          url: '/life-insurance/single-parents-stay-home-parents/',
          image: '/images/menu/common/single-parents.svg'
        },
        {
          title: 'Smokers',
          url: '/life-insurance/smokers/',
          image: '/images/menu/common/smokers.svg'
        }
      ]
    },

    secondColumn: {
      title: 'Cover by type',
      links: [
        {
          title: 'Death Cover',
          url: '/life-insurance/what-is-life-insurance/',
          image: '/images/menu/life-insurance/death-cover.svg'
        },
        {
          title: 'Total & Permanent Disability (TPD)',
          url: '/life-insurance/tpd-insurance/',
          image: '/images/menu/life-insurance/permanent-disability.svg'
        },
        {
          title: 'Trauma Cover',
          url: '/life-insurance/trauma-insurance/',
          image: '/images/menu/life-insurance/trauma.svg'
        },
        {
          title: 'Income Protection',
          url: '/life-insurance/income-protection/quote/',
          image: '/images/menu/life-insurance/income-protection.svg'
        },
        {
          title: 'Funeral Cover',
          url: '/life-insurance/funeral-insurance/',
          image: '/images/menu/life-insurance/funeral-cover.svg'
        },
        {
          title: 'Cover in Your Super',
          url: '/life-insurance/super/',
          image: '/images/menu/life-insurance/cover-in-super.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Life Insurance Explained',
      links: [
        {
          title: 'All guides',
          url: '/life-insurance/guides/',
          underline: true
        }
      ]
    },

    details: {
      productPage: '/life-insurance/'
    }
  },
  'Car Insurance': {
    title: 'Car Insurance',

    firstColumn: {
      title: 'Insurer',
      links: [
        {
          title: 'ahm',
          url: 'https://compareclub.com.au/car-insurance/ahm-car-insurance',
          image: '/images/menu/common/AHM.svg'
        },
        {
          title: 'Carpeesh',
          url: 'https://compareclub.com.au/car-insurance/carpeesh-car-insurance',
          image: '/images/menu/common/Carpeesh.svg'
        }
      ]
    },
    secondColumn: {
      title: 'Cover by type',
      links: [
        {
          title: 'Comprehensive',
          url: 'https://compareclub.com.au/car-insurance/comprehensive',
          image: '/images/menu/common/Comprehensive.svg'
        },
        {
          title: 'Pay Per Kilometre',
          url: 'https://compareclub.com.au/car-insurance/disclose-pay-per-km-usage-for-insurance-savings',
          image: '/images/menu/common/Pay-Per-Kilometre.svg'
        },
        {
          title: 'Third Party Damage',
          url: 'https://compareclub.com.au/car-insurance/third-party-property-damage',
          image: '/images/menu/common/Third-Party-Damage.svg'
        },
        {
          title: 'Third Party Fire & Theft',
          url: 'https://compareclub.com.au/car-insurance/third-party-fire-and-theft',
          image: '/images/menu/common/Third-Party-Fire-And-Theft.svg'
        }
      ]
    },
    thirdColumn: {
      title: 'Car Insurance Explained',
      links: [
        {
          title: 'All guides',
          url: '/car-insurance/guides/',
          underline: true
        }
      ]
    },
    details: {
      productPage: '/car-insurance/'
    }
  }
}

const EXPERT_ANALYSIS_MEGAMENU_OBJ = {
  'Expert Analysis': {
    title: 'Expert Analysis',
    firstColumn: {
      title: 'Personal finance news',
      links: [
        {
          title: 'Loans and debt',
          url: '/expert-analysis/loans-and-credit/',
          image: '/images/menu/expert-analysis/loans-and-debts.svg'
        },
        {
          title: 'Insurance',
          url: '/expert-analysis/insurance/',
          image: '/images/menu/expert-analysis/insurance.svg'
        },
        {
          title: 'Utilities',
          url: '/expert-analysis/utilities/',
          image: '/images/menu/expert-analysis/utilities.svg'
        }
      ]
    },

    secondColumn: {
      title: '',
      links: [
        {
          title: 'Banking and super',
          url: '/expert-analysis/savings-and-super/',
          image: '/images/menu/expert-analysis/banking-and-super.svg'
        },
        {
          title: 'Life events',
          url: '/expert-analysis/life-events/',
          image: '/images/menu/expert-analysis/life-events.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Top News',
      contentType: 'news',
      sortBy: 'date',
      links: []
    },

    details: {
      productPage: '/expert-analysis/'
    }
  }
}

const CREDIT_AND_LOANS_MEGAMENU_OBJ = {
  'Home Loans': {
    title: 'Home Loans',

    firstColumn: {
      title: 'By type',
      links: [
        {
          title: 'Fixed rate', url: '/home-loans/fixed-rate/', image: '/images/menu/home-loans/fixed-rate.svg'
        },
        {
          title: 'Variable rate', url: '/home-loans/variable/', image: '/images/menu/home-loans/variable-rate.svg'
        },
        {
          title: 'Refinancing', url: '/home-loans/refinancing/', image: '/images/menu/home-loans/refinancing.svg'
        },
        {
          title: 'First home', url: '/home-loans/everything-to-know-buying-first-home/', image: '/images/menu/home-loans/first-home.svg'
        },
        {
          title: 'Construction loans', url: '/home-loans/construction-loans/', image: '/images/menu/home-loans/construction-loan.svg'
        },
        {
          title: 'Self-employed', url: '/home-loans/independent-contractors/', image: '/images/menu/home-loans/self-employed.svg'
        }
      ]
    },

    secondColumn: {
      title: 'By activity',
      links: [
        {
          title: 'Home Loans calculators',
          url: '/home-loans/calculators/',
          image: '/images/menu/home-loans/calculator.svg'
        },
        {
          title: 'RBA Rate Tracker',
          url: '/home-loans/interest-rates/',
          image: '/images/menu/home-loans/rate-tracker.svg'
        },
        {
          title: 'Renovations',
          url: '/home-loans/finance-home-addition/',
          image: '/images/menu/home-loans/renovations.svg'
        },
        {
          title: 'Buying a Duplex',
          url: '/home-loans/buying-duplex-first-home/',
          image: '/images/menu/home-loans/duplex.svg'
        },
        {
          title: 'Home addition',
          url: '/home-loans/finance-home-addition/',
          image: '/images/menu/home-loans/home-addition.svg'
        },
        {
          title: 'Book an appointment',
          url: '/home-loans/quote/form/step1/',
          image: '/images/menu/home-loans/book-appointment.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Home Loans Explained',
      links: [
        {
          title: 'All guides',
          url: '/home-loans/guides/',
          underline: true
        }
      ]
    },

    details: {
      productPage: '/home-loans/'
    }
  },
  'Car Loans': {
    title: 'Car Loans',

    firstColumn: {
      title: 'Car Loans Explained',
      links: [
        {
          title: 'How to compare',
          url: '/car-loans/how-to-compare/',
          image: '/images/menu/car-loans/how-to-compare.svg'
        },
        {
          title: 'Rent-to-own',
          url: '/car-loans/rent-to-own-cars/',
          image: '/images/menu/car-loans/rent-to-own.svg'
        },
        {
          title: 'Pre-approved Loans',
          url: '/car-loans/pre-approved-car-loans/',
          image: '/images/menu/car-loans/preapproved.svg'
        },
        {
          title: 'Balloon payments',
          url: '/car-loans/balloon-payments/',
          image: '/images/menu/car-loans/balloon-payments.svg'
        }
      ]
    },

    secondColumn: {
      title: 'I want to...',
      links: [
        {
          title: 'Speak to an expert',
          url: 'tel:1300 144 721',
          image: '/images/menu/car-loans/speak-to-expert.svg'
        },
        {
          title: 'Compare Lenders',
          url: 'https://carloans.compareclub.com.au/start-application/',
          image: '/images/menu/car-loans/compare-lenders.svg'
        },
        {
          title: 'Beat my dealer\'s rate',
          url: '/car-loans/beat-the-dealer/',
          image: '/images/menu/car-loans/beat-my-dealers-rate.svg'
        }
      ]
    },

    details: {
      productPage: '/car-loans/'
    }
  },
  'Credit Cards': {
    title: 'Credit Cards',

    firstColumn: {
      title: 'What are you looking for?',
      links: [
        {
          title: 'Frequent flyer cards',
          url: '/credit-cards/frequent-flyer-cards/',
          image: '/images/menu/credit-cards/frequent-flyer-cards.svg'
        },
        {
          title: 'Balance transfer cards',
          url: '/credit-cards/balance-transfer-cards/',
          image: '/images/menu/credit-cards/balance-transfer-cards.svg'
        },
        {
          title: 'Premium cards',
          url: '/credit-cards/premium-cards/',
          image: '/images/menu/credit-cards/premium-cards.svg'
        }
      ]
    },

    secondColumn: {
      title: '',
      links: [
        {
          title: 'Low fee cards',
          url: '/credit-cards/low-fee-cards/',
          image: '/images/menu/credit-cards/low-fee-cards.svg'
        },
        {
          title: 'Cash back cards',
          url: '/credit-cards/cash-back-cards/',
          image: '/images/menu/credit-cards/cashback-cards.svg'
        },
        {
          title: 'Reward cards',
          url: '/credit-cards/rewards-cards/',
          image: '/images/menu/credit-cards/reward-cards.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Credit cards explained',
      links: [
        {
          title: 'All guides',
          url: '/credit-cards/guides/',
          underline: true
        }
      ]
    },

    details: {
      productPage: '/credit-cards/'
    }
  },
  'Personal Loans': {
    title: 'Personal Loans',

    firstColumn: {
      title: 'By type',
      links: [
        {
          title: 'Emergency loans',
          url: '/personal-loans/no-money-in-bank/',
          image: '/images/menu/personal-loans/emergency-loans.svg'
        },
        {
          title: 'Debt consolidation',
          url: '/personal-loans/debt-consolidation/',
          image: '/images/menu/personal-loans/debt-consolidation.svg'
        },
        {
          title: 'Home renovation',
          url: '/personal-loans/renovations/',
          image: '/images/menu/personal-loans/home-renovations.svg'
        }
      ]
    },

    secondColumn: {
      title: 'I want to...',
      links: [
        {
          title: 'Compare all loans',
          url: 'https://personalloans.compareclub.com.au/apply/loan-details/amount?campaign_code=cFxYaAMACwVOTA&utm_source=direct&v2=direct&utm_medium=organic&v1=organic&website_source=CompareClub&landing_page=https://compareclub.com.au/privacy-policy/&ref=https://compareclub.com.au/privacy-policy/',
          image: '/images/menu/personal-loans/compare-all.svg'
        }
      ]
    },

    details: {
      productPage: '/personal-loans/'
    }
  }
}

const ENERGY_MEGAMENU_OBJ = {
  Energy: {
    title: 'Energy',

    firstColumn: {
      title: 'Where are you located?',
      links: [
        {
          title: 'New South Wales',
          url: '/energy/compare-energy-nsw/',
          image: '/images/menu/regions/nsw.svg'
        },
        {
          title: 'Victoria',
          url: '/energy/compare-energy-vic/',
          image: '/images/menu/regions/vic.svg'
        },
        {
          title: 'Queensland',
          url: '/energy/compare-energy-qld/',
          image: '/images/menu/regions/qld.svg'
        },
        {
          title: 'South Australia',
          url: '/energy/compare-energy-sa/',
          image: '/images/menu/regions/sa.svg'
        },
        {
          title: 'Australia Capital Territory',
          url: '/energy/compare-energy-act/',
          image: '/images/menu/regions/act.svg'
        },
        {
          title: 'Tasmania',
          url: '/energy/compare-energy-tas/',
          image: '/images/menu/regions/tas.svg'
        }
      ]
    },

    secondColumn: {
      title: 'Energy by type',
      links: [
        {
          title: 'Electricity',
          url: '/energy/electricity-pricing-structure/',
          image: '/images/menu/energy/electricity.svg'
        },
        {
          title: 'Gas',
          url: '/energy/gas-charges/',
          image: '/images/menu/energy/gas.svg'
        },
        {
          title: 'Solar',
          url: '/energy/solar-save-energy-money/',
          image: '/images/menu/energy/solar.svg'
        },
        {
          title: 'Moving House',
          url: '/energy/moving-house/',
          image: '/images/menu/energy/moving-house.svg'
        }
      ]
    },

    thirdColumn: {
      title: 'Energy explained',
      links: [
        {
          title: 'All guides',
          url: '/energy/guides/',
          underline: true
        }
      ]
    },

    details: {
      productPage: '/energy/'
    }
  }
}

export const NAV_MENU_OBJ = {
  insurance: {
    title: 'Insurance',
    slug: 'insurance',
    type: 'dropdown',
    theme: 'global-navbar',
    menu: INSURANCE_MEGAMENU_OBJ
  },
  credit_and_loans: {
    title: 'Credit & loans',
    slug: 'credit_and_loans',
    type: 'dropdown',
    theme: 'global-navbar',
    menu: CREDIT_AND_LOANS_MEGAMENU_OBJ
  },
  energy: {
    title: 'Energy',
    slug: 'energy',
    type: 'default',
    theme: 'global-navbar',
    menu: ENERGY_MEGAMENU_OBJ
  },
  expert_analysis: {
    title: 'Expert Analysis',
    slug: 'expert_analysis',
    type: 'default',
    theme: 'global-navbar',
    menu: EXPERT_ANALYSIS_MEGAMENU_OBJ
  }
}
