<template>
  <div
    class="h-full"
    @mouseover="show=true"
    @mouseleave="show=false"
  >
    <div
      tabindex="0"
      class="flex items-center w-full h-full px-2 xs:px-4"
      :class="{
        'bg-gray-10': show
      }"
      @click="() => show = !show"
    >
      <slot name="title" />

      <span
        v-if="!hideDropIcons"
        class="ml-2"
      >
        <IconsChevron
          class="ml-2 w-4 h-4 relative top-[0.0625rem]"
          type="thin"
          :direction="show ? 'top': 'bottom'"
        />
      </span>
    </div>

    <div
      v-show="show"
      class="absolute bg-gray-10 rounded-b-md w-max right-0 top-16 shadow-md min-w-[11.5rem]"
      @click="handleClose"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'AtomsDropdown'
})

defineProps({
  hideDropIcons: {
    type: Boolean,
    default: false
  }
})

// vairables
const show = ref(false)

// functions
const handleClose = async () => {
  await wait(200)
  show.value = false
}
</script>
