<template>
  <div
    id="main-nav"
    class="z-[99]"
    :class="{
      'shadow-md': ctaOnly
    }"
  >
    <div
      class="bg-white relative w-full z-[999]"
      :class="{
        'shadow-header': hasShadow
      }"
      @mouseleave="hideMenu"
    >
      <div
        class="flex justify-between items-center max-w-8xxl mx-auto h-[4rem] lg:pl-8 lg:pr-6 xl:px-14 shadow-sm"
        @mouseover="hideMenu"
        @click="hideMenu"
      >
        <div class="flex items-center">
          <nuxt-link to="/">
            <NuxtImg
              height="28"
              width="167"
              class="min-w-[9rem] max-w-[9rem] xs:max-w-unset xs:min-w-[10.4375rem] lg:w-auto py-3 pl-4"
              src="/images/cc-logo-new.svg"
              alt="Compare Club"
            />
          </nuxt-link>

          <div
            v-show="vertical && !excludeVertical"
            class="w-px mx-4 h-8 bg-dark hidden md:inline-block lg:hidden xl:inline-block"
            :class="{
              'hidden md:inline-block': ctaOnly || verticalData?.verticalTabletTitle === ''
            }"
          >
          </div>

          <a
            v-show="vertical && !excludeVertical"
            :href="verticalData?.productPage"
            class="font-bold lg:text-lg hidden md:inline-block lg:hidden xl:inline-block"
            :class="{
              'hidden md:inline-block': ctaOnly || verticalData?.verticalTabletTitle === ''
            }"
          >
            <span
              :class="{
                'hidden lg:block': !ctaOnly && verticalData?.verticalTabletTitle !== ''
              }"
            >
              {{ verticalData?.title }}
            </span>

            <span
              :class="{
                'lg:hidden': !ctaOnly,
                'hidden': ctaOnly
              }"
            >
              {{ verticalData?.verticalTabletTitle }}
            </span>
          </a>
        </div>

        <div
          v-if="ctaOnly"
          class="py-3"
        >
          <atoms-cta
            :to="verticalData?.formPage"
            :size="devices.breakpoint('md') ? 'medium' : 'xsmall'"
            theme="primaryWhite"
            class="font-bold px-4"
          >
            {{ ctaText }}
          </atoms-cta>
        </div>

        <!-- DESKTOP MENU -->
        <atoms-menu
          v-else
          class="nav-menu hidden lg:flex h-full min-h-[4rem]"
          :class="{
            // 'md:h-24': verticalData?.phoneNumber === ''
          }"
        >
          <atoms-menu-item
            v-for="(item, itemIndex) in menu"
            :key="itemIndex"
            :theme="item.theme"
            :is-dropdown="item.type === 'dropdown'"
            :active="currMenu === item.slug"
            class="menu-container !text-dark"
            padding="small"
            @mouseover.stop="setCurrentMenu((item?.menu && item.slug) || null)"
            @click.stop="setCurrentMenu((item?.menu && item.slug) || null)"
          >
            {{ item.title }}
          </atoms-menu-item>

          <template v-if="!userLoggedIn && !hideSignupButtons">
            <atoms-menu-item
              padding="small"
              :to="!hasLoginPopup ? '/login/' : null"
              theme="global-navbar"
              class="menu-other"
              @click="showClubPlusLogin"
            >
              <NuxtImg
                src="/icons/user.svg"
                alt="user"
                class="ml-0"
                width="24"
                height="24"
              />

              <span class="text-dark ml-2 mr-0">
                Login
              </span>
            </atoms-menu-item>

            <atoms-menu-item
              padding="small"
              :to="!hasLoginPopup ? '/register/' : null"
              theme="global-navbar"
              class="menu-other"
              @click="showClubPlusLogin"
            >
              <atoms-cta
                theme="destructive"
                size="micro"
                class="!h-auto"
              >
                Join the Club
              </atoms-cta>
            </atoms-menu-item>
          </template>
          <!-- Start: Skeleton Loader for logged in user -->
          <div
            v-if="hideSignupButtons && !userLoggedIn"
            class="flex items-center min-w-32 h-full px-2 xs:px-4 animate-pulse"
          >
            <svg
              class="w-6 h-6 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <div class="h-4 bg-gray-400 ml-2 min-w-12"></div>
            <span class="ml-2">
              <IconsChevron
                class="ml-2 w-4 h-4 relative top-[0.0625rem]"
                type="thin"
                direction="bottom"
              />
            </span>
          </div>
          <!-- End: Skeleton Loader for logged in user -->
          <atoms-menu-item
            v-if="userLoggedIn"
            padding="none"
            theme="global-navbar"
            class="menu-other"
          >
            <MoleculesNavProfile class="w-full h-full flex items-center" />
          </atoms-menu-item>

          <template
            v-if="
              verticalData?.phoneNumber !== '' &&
                hasCallCta
            "
          >
            <li class="inline-flex items-center pr-2 lg:pr-4">
              <div class="inline-block h-8 w-px bg-dark"></div>
            </li>

            <atoms-menu-item
              :to="`tel:${verticalData?.phoneNumber}`"
              :has-background-when-active="false"
              class="menu-other relative min-w-[8.25rem]"
              padding="none"
              @mouseover="showSpeakToUsTooltip = true"
              @mouseleave="showSpeakToUsTooltip = false"
            >
              <div class="block text-right">
                <div class="flex items-center justify-end mt-3">
                  <NuxtImg
                    src="/icons/phone-primary.svg"
                    class="mr-1"
                    alt="phone"
                    width="14"
                    height="14"
                  />

                  <atoms-badge
                    size="sm"
                    class="text-xs px-2"
                  >
                    Speak to us
                  </atoms-badge>
                </div>

                <div class="flex items-center justify-end mb-2">
                  <span class="text-dark text-base">
                    {{ verticalData?.phoneNumberDisplay || verticalData?.phoneNumber }}
                  </span>
                </div>
              </div>

              <template #after>
                <atoms-tooltip
                  v-model="showSpeakToUsTooltip"
                  type="call-hours"
                  tip-x="right"
                  tip-y="top"
                  :has-icon="false"
                  class="absolute right-6 top-14 w-[12.25rem] z-[51] text-left"
                >
                  <NuxtImg
                    src="icons/clock.svg"
                    class="mt-[0.125rem] mr-[0.313rem] inline-block align-top"
                    alt="clock"
                    :width="12.94"
                  />

                  <p class="inline-block text-left">
                    <span class="flex items-center justify-start">
                      {{ verticalData?.schedule }}
                    </span>

                    <span class="flex items-center justify-start">
                      {{ verticalData?.schedule_weekend }}
                    </span>
                  </p>
                </atoms-tooltip>
              </template>
            </atoms-menu-item>
          </template>
        </atoms-menu>

        <!-- MOBILE MENU -->
        <atoms-menu
          class="nav-menu flex lg:hidden h-[4rem]"
          :class="{
            'md:h-24': verticalData?.phoneNumber === ''
          }"
        >
          <template
            v-if="!ctaOnly && layout !== 'minimal' && verticalData?.phoneNumber !== '' && hasCallCta"
          >
            <atoms-menu-item
              class="min-w-[2.5rem] xs:min-w-[3.25rem]"
              padding="none"
              inner-wrapper-class="justify-center"
              :to="`tel:${verticalData?.phoneNumber}`"
              :has-background-when-active="false"
            >
              <NuxtImg
                src="/icons/phone-primary.svg"
                class="w-6 h-6 mx-auto"
                alt="phone"
                width="24"
                height="24"
              />
            </atoms-menu-item>

            <li class="inline-flex items-center">
              <div class="inline-block h-8 w-px bg-dark">
              </div>
            </li>
          </template>

          <atoms-menu-item
            v-if="!userLoggedIn"
            padding="none"
            :to="!hasLoginPopup ? '/login/' : null"
            theme="global-navbar"
            inner-wrapper-class="justify-center"
            class="min-w-[2.5rem] xs:min-w-[3.25rem]"
            @click="showClubPlusLogin"
          >
            <NuxtImg
              src="/icons/user.svg"
              alt="user"
              width="24"
              height="24"
              class="w-6 h-6 mx-auto"
            />
          </atoms-menu-item>

          <atoms-menu-item
            v-if="userLoggedIn && (layout !== 'minimal' || !isProfileIconOnly)"
            padding="none"
            inner-wrapper-class="justify-center"
            theme="global-navbar"
            class="min-w-[2.5rem] xs:min-w-[3.25rem]"
          >
            <MoleculesNavProfile
              class="w-full h-full flex items-center"
              icon-only
            />
          </atoms-menu-item>

          <li
            v-show="!userLoggedIn || (userLoggedIn && layout !== 'minimal')"
            class="inline-flex items-center"
          >
            <div class="inline-block h-8 w-px bg-dark"></div>
          </li>

          <atoms-menu-item
            v-if="!ctaOnly && layout === 'minimal' && !userLoggedIn && verticalData?.phoneNumber !== '' && hasCallCta"
            class="min-w-[3.25rem]"
            padding="small"
            inner-wrapper-class="justify-center"
            :to="`tel:${verticalData?.phoneNumber}`"
            :has-background-when-active="false"
          >
            <NuxtImg
              src="/icons/phone-primary.svg"
              class="w-6 h-6 mx-auto"
              alt="phone"
              width="24"
              height="24"
            />
          </atoms-menu-item>

          <li
            v-if="!ctaOnly && layout !== 'minimal'"
            class="min-w-[2.5rem]"
          >
            <button
              class="inline-flex items-center lg:hidden text-sm h-full w-full"
              type="button"
              @click.stop="toggleMegaMenu"
            >
              <NuxtImg
                v-show="!showCurrMenu"
                src="/icons/burger-dark.svg"
                alt="burger"
                height="24"
                class="mx-auto w-6 h-6 md:w-auto"
                width="24"
              />

              <NuxtImg
                v-show="showCurrMenu"
                src="/icons/close-dark.svg"
                alt="close"
                height="24"
                class="mx-auto w-6 md:w-auto"
                width="24"
              />
            </button>
          </li>
        </atoms-menu>
      </div>

      <OrganismsNavMegaMenu
        v-show="!ctaOnly && currMenu && showCurrMenu"
        :curr-menu="currMenu"
        :menu="currMenuList"
        @redirect="hideMenu"
      />
    </div>

    <div
      v-if="showCurrMenu"
      class="mega-menu-item z-[61] nav-menu-mobile fixed top-0 left-0 pt-15 lg:hidden w-full border-t border-t-gray-400"
    >
      <div class="overflow-x-hidden overflow-y-auto">
        <atoms-menu class="w-full bg-gray-200">
          <atoms-menu-item
            v-for="(item, itemKey) in menu"
            :key="itemKey"
            :active="currMenu === item.slug"
            :to="item.to"
            padding="largeY"
            theme="hover-with-border-full-width"
            class="grow text-center relative"
            v-on="(item?.menu && {
              click: () => setCurrentMenu(item.slug)
            } || {})"
          >
            <span class="text-dark">
              {{ item.title }}
            </span>
          </atoms-menu-item>
        </atoms-menu>

        <OrganismsNavMobileMenu
          :id="currMenu"
          ref="navMobileMenu"
          :curr-menu="currMenu"
          :menu="currMenuList"
          :user-logged-in="userLoggedIn"
        />

        <div
          class="bg-white flex px-2 py-5 gap-4"
        >
          <div class="w-1/2">
            <atoms-cta
              :to="!userLoggedIn ? '/register/' : '/dashboard/'"
              :theme="!userLoggedIn ? 'destructive' : 'secondary'"
              size="mini"
              class="uppercase !h-auto whitespace-nowrap"
              full
            >
              {{
                !userLoggedIn
                  ? 'Join the Club'
                  : 'View Dashboard'
              }}
            </atoms-cta>
          </div>

          <div
            v-if="hasCallCta"
            class="w-1/2 flex flex-col text-center gap-2"
          >
            <atoms-cta
              :to="`tel:${verticalData?.phoneNumber}`"
              theme="primary"
              size="mini"
              class="uppercase justify-center text-center inline-flex items-center whitespace-nowrap"
              full
            >
              <NuxtImg
                src="/icons/phone-dark.svg"
                alt="phone"
                width="12"
                height="12"
                class="mr-2"
              />

              <span>Speak with us</span>
            </atoms-cta>

            <div class="text-xs font-bold">
              <p>
                {{ verticalData.schedule }}
              </p>

              <p>
                {{ verticalData.schedule_weekend }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showCurrMenu"
      ref="backdrop"
      class="bg-black fixed w-full h-full top-0 left-0 z-[60] opacity-50 block"
      @click="hideMenu"
    >
    </div>

    <MoleculesRateRiseNudgeBar
      :show="showRateRiseNudgeBar"
      :config="dualRateConfig"
    />

    <OrganismsMemberhubModalAuthentication />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import { NAV_MENU_OBJ } from '~/constants/navbar.constants'

const { devices } = deviceChecker()
const { $tracking, $bus } = useNuxtApp()
const { fetchData } = useNavigationStore()

const userStore = useUserStore()

const layoutStore = useLayoutsStore()
const { vertical, navigation } = storeToRefs(layoutStore)

const rateRiseNudgebar = useRateRiseNudgebarStore()
const {
  dualRateConfig,
  showRateRiseNudgeBar
} = storeToRefs(rateRiseNudgebar)

defineOptions({
  name: 'OrganismsNav'
})

const route = useRoute()
const props = defineProps({
  layout: {
    type: String,
    default: 'default',
    validator: value => ['default', 'minimal'].includes(value)
  },

  ctaOnly: {
    type: Boolean,
    default: false
  },

  ctaText: {
    type: String,
    default: 'GET FREE QUOTES'
  },

  hasShadow: {
    type: Boolean,
    default: false
  },

  hasCallCta: {
    type: Boolean,
    default: true
  },

  hideSignupButtons: {
    type: Boolean,
    default: false
  },

  hasLoginPopup: {
    type: Boolean,
    default: false
  }
})

const $route = useRoute()

/**
 * Fetch the list for feature menu items
 */
if (import.meta.server) {
  try {
    await fetchData({ verticals: VERTICAL_LIST })
  } catch (error) {
    throw new Error('Navigation:fetchData:Fail:' + String(error))
  }
}

const currMenu = ref('')
const showCurrMenu = ref(false)
const userLoggedIn = computed(() => userStore.isUserLoggedIn)
const showSpeakToUsTooltip = ref(false)

const navMobileMenu = ref('')

const menu = computed(() => {
  if (props.layout === 'minimal') {
    return {}
  }

  return NAV_MENU_OBJ
})

const isProfileIconOnly = computed(() => {
  const _path = $route.path
  return !(
    _path.includes('dashboard')
  )
})
const currMenuList = computed(() => menu.value?.[currMenu.value])

const verticalData = computed(() => {
  const vert = vertical.value || 'Default'
  const data = VERTICALS?.[vert]

  const isIncomeProtection = route.path.includes('income-protection')
  const isOVHC = route.path.includes('visitors-visa-ovhc')

  if (isIncomeProtection) {
    data.schedule = 'Mon-Thu 9:30am-6pm AEST'
    data.schedule_weekend = 'Fri 9am-5pm AEST'
  }

  if (isOVHC) {
    data.phoneNumber = '1300 084 008'
    data.phoneNumberDisplay = '1300 084 008'
  }

  return data
})

const excludeVertical = computed(() => {
  return route.path.includes('/partners/add') || route.path.includes('/partners/signup')
})

watch(showCurrMenu, val => {
  navigation.showMegaNav = val
})

function setCurrentMenu (val) {
  if (!val) {
    return
  }

  currMenu.value = val
  showCurrMenu.value = true
}

function hideMenu ($event) {
  const type = $event.type

  if (
    (['mouseleave', 'mouseover'].includes(type) && devices.breakpoint('lg')) ||
    type === 'click'
  ) {
    showCurrMenu.value = false
    currMenu.value = ''
  }
}

const toggleMegaMenu = () => {
  showCurrMenu.value = !showCurrMenu.value

  if (
    devices.breakpoint('md', 'max') &&
    (!currMenu.value || currMenu.value === 'hide')
  ) {
    currMenu.value = 'insurance'
  }
}

function showClubPlusLogin () {
  if ( props.hasLoginPopup ) {
    $tracking.customEvent('Club+ Modal', {})
    $bus.emit('mh-auth', { source_page_url: $route.fullPath, query: $route.query })
  }
}
</script>
